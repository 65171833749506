import React from "react";
import { graphql } from "gatsby";

// Components
import {
    Hero,
    About,
    Contact,
    OurBlogs,
    Services,
    OurDoctors,
    CranbourneStatment,
} from "@components";

const IndexPage = ({ data }) => {
    const {
        allStrapiDoctor: { nodes: allDoctors },
    } = data;

    const doctors = allDoctors.length
        ? allDoctors.filter((doctor) => !doctor.sample)
        : [];
    return (
        <div className="flex flex-col gap-y-20">
            <Hero doctors={doctors} />
            <About />
            <Services />
            <CranbourneStatment />
            <OurDoctors doctors={doctors} />
            <OurBlogs />
            <Contact />
        </div>
    );
};

export const query = graphql`
    query {
        # Doctors
        allStrapiDoctor {
            nodes {
                id
                slug
                name
                bio
                languages
                qualification
                interests
                services {
                    name
                    phone
                    description
                }
                specialization {
                    name
                }
                image {
                    alternativeText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                placeholder: DOMINANT_COLOR
                                formats: [AUTO, WEBP, AVIF]
                            )
                            fluid(quality: 90, maxWidth: 1920) {
                                ...GatsbyImageSharpFluid_withWebp
                            }
                        }
                    }
                    url
                }
                sample
            }
        }
    }
`;

export default IndexPage;
